'use client';

import { useTranslation } from 'react-i18next';
import { Button, IconButton, Text, XStack } from '@cxnpl/ui';
import { Copy } from '@cxnpl/ui/icons';
import { copyToClipboard } from 'app/utils/copyToClipboard';
import { useToast } from 'app/hooks';
import { FullScreenError } from './components';
import { forceReload } from './helpers/forceReload';

export function UnexpectedError({ errorTrackerId }: { errorTrackerId?: string }) {
  const { t } = useTranslation();
  const { showToast } = useToast();

  return (
    <FullScreenError
      variant="with-logo-and-background"
      title={t('common.errorBoundary.title.unexpected')}
      message={
        errorTrackerId
          ? t('common.errorBoundary.message.unexpectedWithTrackerId')
          : t('common.errorBoundary.message.unexpected')
      }
    >
      {errorTrackerId ? (
        <XStack alignItems="center" justifyContent="center">
          <Text>{errorTrackerId}</Text>
          <IconButton
            icon={<Copy />}
            aria-label={`Copy error tracker ID ${errorTrackerId}`}
            size="md"
            onPress={async () => {
              try {
                await copyToClipboard(errorTrackerId);
                showToast({ title: t('accounts.home.copiedToClipboard'), severity: 'info' });
              } catch (error) {
                showToast({ title: t('accounts.home.copiedToClipboardFail'), severity: 'danger' });
              }
            }}
            variant="icon-only"
            iconProps={{ color: '$border/secondary-press' }}
            padding={0}
          />
        </XStack>
      ) : null}
      <Button onPress={forceReload}>{t('common.errorBoundary.buttons.backToHome')}</Button>
    </FullScreenError>
  );
}
