import { useMemo } from 'react';
import { useRouter as useSolitoRouter } from 'solito/navigation';
import { useExpoNavigationContainerRef } from './useExpoNavigationContainerRef';
import { useIsEmbeddedModeEnabled } from 'app/services/embeddedMode';
import { useNextRouter } from './useNextRouter';
import { Platform } from 'react-native';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { postMessageToNative } from 'app/features/WebView/messageNative';
import { EMBEDDED_PATH } from 'app/services/embeddedMode/types';

/**
 * Wrapper over solito router, with next router specific methods, similar to how solito wraps next router and react navigation
 * https://github.com/nandorojo/solito/blob/master/src/router/use-router.ts
 */
export const useRouter = () => {
  const solitoRouter = useSolitoRouter();
  const nextRouter = useNextRouter();
  const expoRootNavigation = useExpoNavigationContainerRef();
  const isEmbedded = useIsEmbeddedModeEnabled();

  return useMemo(
    () => ({
      ...solitoRouter,
      prefetch: (
        href: Parameters<AppRouterInstance['prefetch']>[0],
        options?: Parameters<AppRouterInstance['prefetch']>[1]
      ) => {
        if (Platform.OS === 'web') {
          nextRouter?.prefetch(href, options);
        } else {
          // Do nothing
        }
      },
      push: (href: Parameters<typeof solitoRouter.push>[0], _paramName?: string, _paramValue?: string) => {
        if (isEmbedded && !href.startsWith(EMBEDDED_PATH)) {
          const embeddedRoute = EMBEDDED_PATH + href.replace(/^\/(.*)$/, '$1');
          solitoRouter.push(embeddedRoute);
        } else {
          solitoRouter.push(href);
        }
        if (isEmbedded) postMessageToNative({ type: 'webViewRoutePath', payload: href });
      },
      replace: (href: Parameters<typeof solitoRouter.push>[0], _paramName?: string, _paramValue?: string) => {
        if (isEmbedded && !href.startsWith(EMBEDDED_PATH)) {
          let embeddedRoute = EMBEDDED_PATH + href.replace(/^\/(.*)$/, '$1');
          solitoRouter.replace(embeddedRoute);
        } else {
          solitoRouter.replace(href);
        }
        if (isEmbedded) postMessageToNative({ type: 'webViewRoutePath', payload: href });
      },
      refresh: () => {
        if (Platform.OS === 'web') {
          nextRouter?.refresh();
        } else {
          // Do nothing
        }
      },
      // Set navigation stack to a specific state. Only for native.
      // Expo router does not have a reset method. This is a workaround.
      // https://github.com/expo/router/discussions/495#discussioncomment-8618506
      reset: (state: Parameters<typeof expoRootNavigation.reset>[0]) => {
        if (Platform.OS !== 'web') {
          expoRootNavigation.reset(state);
        }
      },
      getState: () => {
        if (Platform.OS === 'web') {
          return null;
        }
        return expoRootNavigation.getState();
      },
    }),
    []
  );
};
